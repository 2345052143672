@use 'sass:math';

/**
 * see: https://css-tricks.com/snippets/css/fluid-typography/
 */
@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/* Fluid Typography parameters */
$min_width: 320px; /* width of iphone5 */
$max_width: 1200px;

/* 12px is the smallest legible size on mobile, should not go below this */
$min_font: 12px;
$max_font: 36px;

html {
  body {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
    box-sizing: border-box;

    /* move content below navbar */
    padding-top: 80px;
    font-family: 'Montserrat', sans-serif;
    background-color: #293742;
  }

  .springboard-content {
    @include fluid-type($min_width, $max_width, $min_font, 24px);
  }

  .email-unsubscribe {
    @include fluid-type($min_width, $max_width, $min_font, 20px);
  }

  .contact-info {
    @include fluid-type($min_width, $max_width, 18px, 24px);
  }

  .header-office-contact {
    @include fluid-type($min_width, $max_width, $min_font, 20px);
  }

  .caller-date {
    @include fluid-type($min_width, $max_width, 28px, 100px);
  }

  .caller-table {
    @include fluid-type($min_width, $max_width, 20px, 64px);
  }

  .next-session-time {
    @include fluid-type($min_width, $max_width, $min_font, 38px);
  }

  .games-table {
    @include fluid-type($min_width, $max_width, $min_font, 32px);
  }

  .home-disclaimer,
  .tap-n-play {
    @include fluid-type($min_width, $max_width, $min_font, 24px);
  }

  .program-and-pricing-intro {
    @include fluid-type($min_width, $max_width, $min_font, 28px);
  }

  .programs-and-pricing-table {
    @include fluid-type($min_width, $max_width, $min_font, 28px);
  }

  .promotion-list,
  .session-last-update {
    @include fluid-type($min_width, $max_width, $min_font, 18px);
  }

  .bingo-footer {
    @include fluid-type($min_width, $max_width, $min_font, 14px);
  }

  .promo-button {
    @include fluid-type($min_width, $max_width, $min_font, 28px);
  }

  /* specific overrides for blueprint elements */
  .bp3-menu-item,
  .bp3-label,
  .bp3-timepicker-input,
  .bp3-control,
  .bp3-datepicker,
  .bp3-dialog-body,
  .bp3-toast-message {
    font-size: 14px;
  }

  /* Main nav bar styling */
  .header-menu-item,
  .header-menu-title {
    text-transform: uppercase;
  }

  .header-menu-title {
    letter-spacing: 0.2em;
  }

  /* animation for flashingText, see <Text/> */
  @keyframes flashingText {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  /* Custom color for pop over */
  $default: #30404d;
  $navy: #273c75;
  $black: #000000;
  $purple: #303b63;

  /* readonly input fields */
  .bp3-dark .bp3-input[readonly] {
    background: inherit;
  }

  /* Portal elements, i.e popover */
  .main-site-newmarket.bp3-overlay {
    .bp3-popover {
      .bp3-popover-content {
        .bp3-menu {
          background-color: $purple;
        }
      }
    }
  }

  .main-site-richmondhill.bp3-overlay {
    .bp3-popover {
      .bp3-popover-content {
        .bp3-menu {
          background-color: $purple;
        }
      }
    }
  }

  /* light-theme popover */
  .bp3-overlay {
    .light-theme.bp3-popover {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1),
        0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);

      .bp3-popover-content {
        .bp3-menu {
          background: #ffffff;
          color: #000000;
        }
      }
    }
  }
}
